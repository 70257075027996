@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    height: 100vh;
}

.button-bg-blue {
    @apply tw-bg-blue tw-text-white tw-rounded-full tw-px-8 tw-py-2 tw-text-center tw-uppercase
}

.button-bg-yellow {
    @apply tw-bg-yellow tw-rounded-full tw-px-8 tw-py-2 tw-text-center tw-uppercase
}

.seperator {
    @apply tw-border-b-2 tw-border-b-slate-400
}

.header {
    top: 0;
    position: fixed;
    z-index: 999;
    background: linear-gradient(to right,  rgba(255,255,255,1),rgba(255,255,255,1),rgba(255,255,255,0));
}

.scrollHeader {
    top: 0;
    position: fixed;
    z-index: 999;
    background:#ffffff;
}

.menuActive {
    border-bottom-style: solid !important;
    border-bottom-color: rgba(245,198,70, 0.8) !important;
    border: 2px;
    padding: 0px 0px 16.5px 0px;
}

.cookieAlert{
    position: fixed;
    left: 60px;
    bottom: 20px;
    right: 60px;
    padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    z-index: 10;
    background:#ffffff;
}

.rotateimgXY180 {
    -webkit-transform:rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotateY(180deg);
    transform: rotateX(180deg);
  }


    @media (max-width: 768px) {
    .header-blank {
        display: none;
    }
    }

    .rounded-checkbox {
  width:17px;
  height: 17px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: rgb(245 198 70);
}

@layer utilities {
    .clip-path {
        clip-path:  polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)
    }

    .clip-path::before {
        border-radius: 50px;
    }
}
